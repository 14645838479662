$black: #000;
$white: #fff;

$bg-primary: #ff3344;
$bg-secondary: #f2dadd;
$bg-dark: #260609;
$bg-white-1: #fafafa;
$bg-white-2: #f5f5f5;
$bg-gray-1: #dddddd;
$bg-gray-2: #999999;
$bg-gray-3: #444444;
$bg-gray-4: #1b1b1b;
$bg-light-yellow: #fffaeb;
$bg-light-blue: #e4fffe;
$bg-light-green: #ebfeec;

$text-primary: #ff3344;
$text-secondary: #26080a;
$text-dark: #260609;
$text-white-1: #fafafa;
$text-white-2: #f5f5f5;
$text-gray-1: #dddddd;
$text-gray-2: #999999;
$text-gray-3: #444444;
$text-gray-4: #1b1b1b;

$link: #4758ed;
$dark-brown: #26080a;
$light-brown: #f2dadd;
$primary-light: #ff1a72;
$very-light-primary: #ff334430;
$very-light-green: #379f3b30;
$green: #379f3b;
$red: #ee0000;

$breakpoint-sm: 480px;
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1300px;
$breakpoint-xxl: 1440px;
