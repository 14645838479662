@import "/src/styles/variables";

.container {
  width: 100%;
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: space-evenly;
  padding: 40px;

  .footerText {
    display: none;
    position: absolute;
    bottom: 20px;
    left: 50%;
    font-size: 9vw;
    font-weight: 900;
    color: $bg-gray-2;
    z-index: 1;
    max-width: 100vw;
    overflow-x: hidden;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;

    @media (max-width: $breakpoint-sm) {
      font-size: 2.5rem;
      bottom: 30px;
    }
  }

  .left {
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px 0px;
    max-width: 450px;

    .heading {
      font-size: 3rem;
      font-weight: bold;
    }

    .desc {
      color: $bg-gray-3;
      font-size: 26px;
      font-weight: 500;
      line-height: 38px;

      span {
        color: $text-primary;
      }
    }
  }

  .right {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 80vh;

    .box {
      background-color: $bg-white-2;
      border-radius: 20px;
      box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.0718567);
      padding: 30px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      min-width: 550px;
      justify-content: center;

      .title {
        font-weight: bold;
        font-size: 22px;
        color: $text-dark;
      }

      .text {
        font-size: 18px;
        color: $text-gray-3;
      }

      .googleButton {
        display: flex;
        gap: 8px;
        align-items: center;
        width: 100%;
        padding: 10px 20px;
        border-radius: 10px;
        font-size: 1rem;
        text-transform: capitalize;
        position: relative;
        background-color: $dark-brown;

        &:disabled {
          svg {
            fill: $text-gray-2;
          }
        }

        svg {
          height: 16px;
          width: 16px;
        }

        .originalButton {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          height: fit-content;
          width: fit-content;
          opacity: 0;
        }
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    padding: 30px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    gap: 50px;

    .left {
      min-height: fit-content;
      .heading {
        font-size: 2.5rem !important;
      }
    }

    .right {
      min-height: fit-content;

      .box {
        min-width: 330px;
      }
    }
  }
}