.row {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    margin: 15px;
  }
}

.general {
  margin: 25px 10%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  .button{
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 45px;
  }
}

.podcast {
  margin: 25px 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .button{
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 45px;
  }
}

.editor {
  margin: 25px 10%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  .button{
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 45px;
  }
}

.courses {
  margin: 25px 10%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  .button{
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 45px;
  }
}

.sales {
  margin: 25px 10%;

  display: flex;
  justify-content: center;
  flex-direction: column;
  .button{
    margin-right: 25%;
    margin-left: 25%;
    margin-top: 45px;
  }
}

.none {
  display: none;
}

.tabHead {
  font-weight: 900;
}
