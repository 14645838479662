@import "/src/styles/variables";

.spinner {
  width: 36px;
  height: 36px;
  border-top: 4px solid $bg-primary;
  border-right: 4px solid transparent;
  border-radius: 50%;
  animation-name: spinner;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  margin: auto;

  .circle {
    width: 4px;
    height: 4px;
    background-color: $bg-primary;
    border-radius: 50%;
    margin-left: 28px;
    margin-top: 1px;
  }

  @keyframes spinner {
    100% {
      transform: rotate(360deg);
    }
  }
}

.white {
  border-top-color: $white !important;

  .circle {
    background-color: $white;
  }
}

.black {
  border-top-color: $dark-brown !important;

  .circle {
    background-color: $dark-brown;
  }
}

.small {
  width: 18px;
  height: 18px;
  border-top: 3px solid $bg-primary;
  border-right: 3px solid transparent;

  .circle {
    height: 2.5px;
    width: 2.5px;
    margin-left: 13.5px;
    margin-top: -0.5px;
  }
}

.noMargin {
  margin: 0 !important;
}